const config = {};

config.links = {
  main: [
    {
      name: "gmail",
      link: "https://mail.google.com",
    },
    {
      name: "calendar",
      link: "https://calendar.google.com/calendar",
    },
    {
      name: "drive",
      link: "https://drive.google.com/drive/my-drive",
    },
    {
      name: "keep",
      link: "https://keep.google.com/",
    },
    {
      name: "maps",
      link: "https://www.google.com/maps",
    },
    {
      name: "gtranslate",
      link: "https://translate.google.com/",
    },
  ],
  group1: [
    {
      name: "wydatki private",
      link:
        "https://docs.google.com/spreadsheets/d/1-lZD3f4f0ZfRsSKOuE-8Ub-OfTpqSta87aJnrfmoEa4",
    },
    {
      name: "nieruchomosci",
      link:
        "https://docs.google.com/spreadsheets/d/1pkrIwvqUxP8EWj6JlpM8tRNUDU82VWlPbtLj8cjwZ58",
    },
    {
      name: "playground for quick stuff",
      link:
        "https://docs.google.com/spreadsheets/d/1_hAjD_CG1tLC2BVIxJ9mB5KTSoiJsEhJiiijIWVlp2s/edit",
    },
  ],
  group2: [
    {
      name: "[Intive] Outlook",
      link: "http://outlook.office365.com/owa/?realm=intive.com",
    },
    {
      name: "[Midfuel] Notion",
      link:
        "https://www.notion.so/Project-Space-2b1d5e6862754527a2076eb6d62cd8ca",
    },
  ],
};

config.weather = {
  url: "https://api.openweathermap.org/data/2.5/weather",
  apiKey: "5369d0e08969fadf5dd357ca06901f50",
  location: "Wroclaw,pl",
  lang: "en",
  units: "metric",
  updateFrequency: 6000, // loop in miliseconds
};

config.gapi = {
  clientId:
    "199532665570-esp664tr6kmcn6uqeh9v2c5ss4fs5av7.apps.googleusercontent.com",
  apiKey: "AIzaSyAWEFH0b4ljrxkYMIYOiLZeewbsi-1c_rU",
};

config.calendar = {
  updateFrequency: 10000, // loop in miliseconds
};

config.firebase = {
  apiKey: "AIzaSyCgM2AgyN_8K6lwGkgEj74LT4GTL5bYlGA",
  authDomain: "chrome-extenstion-c1e3a.firebaseapp.com",
  databaseURL: "https://chrome-extenstion-c1e3a.firebaseio.com",
  projectId: "chrome-extenstion-c1e3a",
  storageBucket: "chrome-extenstion-c1e3a.appspot.com",
  messagingSenderId: "360385119212",
  appId: "1:360385119212:web:4eef66c9309bd9a8f036c9",
  measurementId: "G-KTSSQ1N01R",
};

// this is done to ommit the whole sing-in processes, not needed since only ONE user will be working with this data
// you should write you own data here, that you set in firebase (realtime database should have acces by at least one user)
// with this credentials (Firebase -> Authentication -> Users -> activate by email -> add user here)
config.user = {
  email: "tooschee@gmail.com",
  pass: "bumtralalachlapiefalapoglebiniestatekplynie",
};

export default config;
