import React from "react";
import moment from "moment";
import "./styles.scss";

const formats = {
  hours: "H:mm",
  date: "dddd, Do MMMM",
};

class Clock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({ date: moment() });
    }, 1000);
  }

  render() {
    const { date } = this.state;
    const jsDate = new Date();

    return (
      <div className="clock">
        <div className="clock__hour">
          {jsDate.getHours() < 10 ? `0${jsDate.getHours()}` : jsDate.getHours()}
          :
          {jsDate.getMinutes() < 10
            ? `0${jsDate.getMinutes()}`
            : jsDate.getMinutes()}
        </div>
        <div className="clock__date">
          {moment.utc(date).format(formats.date)}
        </div>
      </div>
    );
  }
}

export default Clock;
