import React from "react";
import { firebaseApp } from "services/firebase";
import Loading from "components/_shared/Loading/Loading";
import cx from "classnames";
import moment from "moment";
import "./styles.scss";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//every year, new calendar
const statsCalendar = firebaseApp
  .database()
  .ref()
  .child(`statsCalendar-${moment().format("YYYY")}`);

const showingTypes = {
  quarter: "quarter",
};

class Notes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingType: showingTypes.quarter,
      loading: true,
      statsCalendar: [],
      updated: null,
    };
  }

  componentDidMount() {
    statsCalendar
      .once("value")
      .then((snapshot) => {
        this.setState({
          loading: false,
          statsCalendar: Object.values(snapshot.val() || {}),
        });
      })
      .catch((e) => {
        console.error(`${this.constructor.name}: something went wrong: ${e}`);
      });

    statsCalendar.on("value", (snapshot) => {
      this.setState({
        loading: false,
        statsCalendar: Object.values(snapshot.val() || {}),
        updated: moment(),
      });
    });
  }

  handleDayClick = (event) => {
    const { statsCalendar: stateCalendar } = this.state;
    const {
      currentTarget: { dataset },
    } = event;

    if (stateCalendar.find(({ date }) => date === dataset.date)) {
      return;
    }

    statsCalendar.push({
      date: dataset.date,
      types: ["drinking"],
    });
  };

  renderQuarter = () => {
    const { statsCalendar } = this.state;
    const namings = ["previous", "current", "next"];
    const months = {
      current: {
        month: moment(),
        index: moment().month(),
        name: moment().startOf("month").format("MMMM"),
        daysInMonths: moment().daysInMonth(),
      },
      previous: {
        month: moment().subtract(1, "M"),
        index: moment().subtract(1, "M").month(),
        name: moment().subtract(1, "M").startOf("month").format("MMMM"),
        daysInMonths: moment().subtract(1, "M").daysInMonth(),
      },
      next: {
        month: moment().add(1, "M"),
        index: moment().add(1, "M").month(),
        name: moment().add(1, "M").startOf("month").format("MMMM"),
        daysInMonths: moment().add(1, "M").daysInMonth(),
      },
    };

    const daysInQuarter = namings.reduce(
      (accu, current) => accu + months[current].daysInMonths,
      0
    );

    const startingDay = months.previous.month.startOf("month").isoWeekday();

    let days = [];
    // depending on which days month starts, add the missing days as "invisible"
    if (startingDay > 0) {
      days = new Array(startingDay - 1).fill({
        day: "",
        className: "statistics__day--not-visible",
      });
    }

    for (let day = 0; day < daysInQuarter; day++) {
      const previousMonth = moment(months.previous.month);
      const dayIndex = previousMonth.add(day, "days");
      const fullDayFormat = dayIndex.format("DD-MM-YYYY");

      const toPush = {
        day: dayIndex.format("dddd"),
        month: dayIndex.format("MMMM"),
        fullDay: fullDayFormat,
        className: "",
      };

      if (moment(dayIndex).isSame(moment(), "day")) {
        toPush.className = `${toPush.className} statistics__day--today`;
      }

      if (statsCalendar.find(({ date }) => date === fullDayFormat)) {
        toPush.className = `${toPush.className} statistics__day--drinking`;
      }

      days.push(toPush);
    }

    const toRender = _.chunk(days, 7);
    const daySubtitle = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // add months naming on given week - start of prev month is always 0
    const displayMonthNameOnWeekIndex = [0];
    displayMonthNameOnWeekIndex.push(
      Math.ceil(months.current.daysInMonths / 7)
    );
    displayMonthNameOnWeekIndex.push(
      Math.ceil((months.current.daysInMonths + months.next.daysInMonths) / 7)
    );

    return (
      <>
        <div className="column">
          {daySubtitle.map((node) => (
            <div key={node} className="has-text-right statistics__day-sub">
              {node}
            </div>
          ))}
        </div>
        {toRender.map((week, weekIndex) => (
          <div
            key={`week-${weekIndex}`}
            className="statistics__wrapper column has-text-centered"
          >
            {displayMonthNameOnWeekIndex.includes(weekIndex) && (
              <div className="statistics__month">
                {week[week.length - 1].month}
              </div>
            )}
            {week.map((day, dayIndex) => {
              return (
                <div
                  onClick={this.handleDayClick}
                  key={`${weekIndex}-${dayIndex}`}
                  className={cx("statistics__day", day.className)}
                  data-tip={`${day.day} ${day.fullDay}`}
                  data-date={day.fullDay}
                />
              );
            })}
          </div>
        ))}
      </>
    );
  };

  renderQuarterStats = () => {
    const { statsCalendar } = this.state;

    const daysDrinking = statsCalendar.filter(({ types }) =>
      types.includes("drinking")
    ).length;

    const daysinYear = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].reduce(
      (accu, current) => moment().month(current).daysInMonth() + accu,
      0
    );
    const percentage = Math.ceil((daysDrinking / daysinYear) * 100);

    return (
      <>
        <div className="column has-text-centered">
          days drinkig: {daysDrinking}
        </div>
        <div className="column has-text-centered">
          % of days drinking in year: {percentage}%
        </div>
      </>
    );
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return <Loading />;
    }

    return (
      <div className="statistics">
        <div className="columns is-vcentered is-mobile">
          <div className="column" />
          <div className="column statistics__legend">
            <div className="statistics__day statistics__day--drinking statistics__day--legend" />
            <div>Drinking</div>
          </div>
          <div className="column statistics__legend">
            <div className="statistics__day statistics__day--today statistics__day--legend" />
            <div>Today</div>
          </div>
          <div className="column" />
        </div>
        <div className="columns is-gapless is-centered is-mobile">
          {this.renderQuarter()}
        </div>
        <div className="columns is-gapless is-centered is-mobile">
          {this.renderQuarterStats()}
        </div>
        <ReactTooltip />
      </div>
    );
  }
}

export default Notes;
