import React from "react";
import config from "config";
import moment from "moment";
import cx from "classnames";
import "./styles.scss";

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    const events = localStorage.getItem("events");

    this.state = {
      events: events ? JSON.parse(events) : [],
    };
  }

  componentDidMount() {
    this.loadGapi();
    this.eventLoop = setInterval(() => {
      this.getCalendarEvents();
    }, config.calendar.updateFrequency);
  }

  componentWillUnmount() {
    clearInterval(this.eventLoop);
  }

  loadGapi = () => {
    if (window.gapi) {
      return;
    }

    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";

    script.onload = () => {
      window.gapi.load("client:auth2", () => {
        window.gapi.client
          .init({
            apiKey: config.gapi.apiKey,
            clientId: config.gapi.clientId,
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
            ],
            scope: "https://www.googleapis.com/auth/calendar.readonly",
          })
          .then(() => {
            this.getCalendarEvents();
          });
      });
    };

    document.body.appendChild(script);
  };

  prepareCalendarGetter = (calendarId) =>
    new Promise((resolve, reject) => {
      window.gapi.client.calendar.events
        .list({
          calendarId: calendarId,
          timeMin: moment().startOf("day").toISOString(),
          showDeleted: false,
          singleEvents: true,
          maxResults: 50,
          orderBy: "startTime",
        })
        .then((response) => {
          resolve(response.result.items);
        })
        .catch((e) => {
          reject(`prepareCalendarGetter problem: ${prepareCalendarGetter}`);
        });
    });

  getCalendarEvents = () => {
    if (!window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      window.gapi.auth2
        .getAuthInstance()
        .isSignedIn.listen(this.getCalendarEvents);
      window.gapi.auth2.getAuthInstance().signIn();
    }

    window.gapi.client.calendar.calendarList.list().then((data) => {
      const { items } = data.result;
      const calendars = items.map(({ id }) => this.prepareCalendarGetter(id));

      Promise.all(calendars).then((data) => {
        let allEvents = [];
        data.forEach((events) => {
          allEvents = [...allEvents, ...events];
        });
        this.setState((prev) => ({ ...prev, events: allEvents }));
      });
    });
  };

  mapDaysAhead = () => {
    const howManydays = 3;
    const today = {
      start: moment().startOf("day"),
      end: moment().endOf("day"),
    };

    return [...Array(howManydays)].map((val, idx) => {
      if (idx === 0) {
        return today;
      }
      return {
        start: moment(today.start).add(idx, "days"),
        end: moment(today.end).add(idx, "days"),
      };
    });
  };

  mapEventsToDays = () => {
    const { events } = this.state;
    const days = this.mapDaysAhead();

    return days.map((day) => {
      const dayEvents = events.filter(({ start, end }) => {
        const eventStart = moment(start.date || start.dateTime);
        const eventEnd = moment(end.date || end.dateTime);

        return day.start < eventStart && day.end > eventEnd;
      });

      const finalEvents = dayEvents.sort(
        (a, b) =>
          moment(a.start.dateTime).unix() - moment(b.start.dateTime).unix()
      );

      return {
        day,
        events: finalEvents,
      };
    });
  };

  renderEmptyDay = () => (
    <li className="calendar__event calendar__event--empty">
      <i className="far fa-grin calendar__empty-icon" />
    </li>
  );

  renderEvents = (events) =>
    events.map((event) => {
      const start = event.start.dateTime || event.start.date;
      const end = event.end.dateTime || event.end.date;
      const format = "HH:mm";

      return (
        <li
          key={event.id}
          className={cx("calendar__event", {
            "calendar__event--crossed": moment() > moment(end),
          })}
        >
          <div className="calendar__event-summary">{event.summary}</div>
          <div className="calendar__event-time">
            <i className="far fa-clock" />
            {moment(start).format(format)} - {moment(end).format(format)}
          </div>
        </li>
      );
    });

  renderDays = () => {
    const days = this.mapEventsToDays();

    return days.map(({ day, events }) => (
      <div className="columns calendar__day" key={day.start}>
        <div className="column is-one-fifth">
          <p className="calendar__day-number">{day.start.format("DD")}</p>
        </div>
        <div className="column calendar__events-wrapper">
          <ul className="calendar__events">
            {events.length !== 0
              ? this.renderEvents(events)
              : this.renderEmptyDay()}
          </ul>
        </div>
      </div>
    ));
  };

  render() {
    return (
      <div className="container">
        <div className="calendar">{this.renderDays()}</div>
      </div>
    );
  }
}

export default Calendar;
